import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Login } from "../common/_models/login/login";
import { Router } from "@angular/router";
import { AuthService } from "../common/_services/auth/auth.service";
import { JsonErrorMessagesService } from "../shared/_services/json-error-messages/json-error-messages.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertifyService } from "../shared/_services/alertify/alertify.service";
import { DcValidation } from "../shared/_validation/dc-validation";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-auth-login",
  templateUrl: "./auth-login.component.html",
  styleUrls: ["./auth-login.component.css"]
})
export class AuthLoginComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private jsonErrorMessagesService: JsonErrorMessagesService
  ) {}
  // #region
  form: FormGroup;

  

   loginObject: Login = {
    username: '' ,
    password: '' ,
    rememberMe: false,
    token: ''
};



  formErrors = {};

  validationMessages;

  mapFormValues() {
    this.loginObject.username = this.form.value.username;
    this.loginObject.password = this.form.value.password;
    this.loginObject.rememberMe =
      this.form.value.rememberMe === null ? false : true;
  }
  // #endregion

  ngOnInit() {
    this.jsonErrorMessagesService.getJSON().subscribe(messages => {
      this.validationMessages = messages.login;
    });
    this.form = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
      rememberMe: []
    });

    this.form.valueChanges.subscribe(data => {
      DcValidation.logValidationErrors(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
  }

  login() {
    this.spinner.show();
    this.mapFormValues();
    this.authService.login(this.loginObject).subscribe(
      () => {
        //debugger;
        this.alertify.success("logged in Successfully.");
        localStorage.setItem("browserLang", "ar");
      },
      err => {
        this.alertify.error("Incorrect username or password.");
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.router.navigate(["/dashboard"]);
      }
    );
  }
}
