import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthLoginComponent } from "./auth-login/auth-login.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { AdminComponent } from "./layouts/admin/admin.component";
import { SubscriptionDashboardComponent } from "./subscription-dashboard/subscription-dashboard.component";
import { SharedModule } from "./shared/shared.module";
import { AuthRegisterComponent } from "./auth-register/auth-register.component";
import { AuthForgetPasswordComponent } from "./auth-forget-password/auth-forget-password.component";
import { PartialSideBarComponent } from "./partial-side-bar/partial-side-bar.component";
import { PartialTopBarComponent } from "./partial-top-bar/partial-top-bar.component";
import { SubscriptionCompanyInformationComponent } from "./subscription-company-information/subscription-company-information.component";
import { ListComponent } from "./subscription-plan/list/list.component";
import { AddComponent } from "./subscription-plan/add/add.component";
import { AddHeaderInterceptor } from "./core/_interceptors/add-header/add-header.interceptor";
import { CacheHttpRequestInterceptor } from './core/_interceptors/cache-http-request/cache-http-request.interceptor';
import { HttpCacheService } from './core/_interceptors/cache-http-request/http-cache.service';
import { TrackerService } from './common/_services/tracker/trackby.service';
import { ListPaymentComponent } from './subscription-payment/list-payment/list-payment.component';
import { AddPaymentComponent } from './subscription-payment/add-payment/add-payment.component';
import { AuthorizationInterceptor } from './core/_interceptors/authorization/authorization.interceptor';
import { NotificationsComponent } from './notifications/notifications.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
   declarations: [
      AppComponent,
      AuthComponent,
      AuthLoginComponent,
      AdminComponent,
      SubscriptionDashboardComponent,
      AuthRegisterComponent,
      AuthForgetPasswordComponent,
      PartialSideBarComponent,
      PartialTopBarComponent,
      SubscriptionCompanyInformationComponent,
      ListComponent,
      AddComponent,
      ListPaymentComponent,
      AddPaymentComponent,
      NotificationsComponent
      
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      AppRoutingModule,
      SharedModule,
      InfiniteScrollModule
   ],
   providers: [
      TrackerService,
      HttpCacheService,
      { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true }, // Handle Http headers
      { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true }, // Handle Http headers
      { provide: HTTP_INTERCEPTORS, useClass: CacheHttpRequestInterceptor, multi: true }, // Handle Http headers
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule {}
