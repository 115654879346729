import { Injectable } from "@angular/core";
import { RootService } from "src/app/core/_services/root/root.service";
import { Observable } from "rxjs";
import { Feature } from "../../_models/feature/feature";

@Injectable({
  providedIn: "root"
})
export class FeatureService {
  endPoints = {
    feature: "feature"
  };

  constructor(private rootService: RootService) {}
  get(): Observable<Feature[]> {
    return this.rootService.getRoot(this.endPoints.feature);
  }
}
