import { NgModule } from '@angular/core';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-default',
    dateInputFormat: 'DD/MM/YYYY'
  });
}
@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),TabsModule.forRoot()
  ],
  declarations: [],
  exports: [BsDatepickerModule,TabsModule],
  providers: [{ provide: BsDatepickerConfig, useFactory: getDatepickerConfig }]
})
export class NgxBootstrapValorModule { }
