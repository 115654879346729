import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.endsWith("upload")) return next.handle(req);

    //Language
    let lang = localStorage.getItem("browserLang");
    if (lang === null) lang = "ar";

    const jsonReq: HttpRequest<any> = req.clone({
      setHeaders: {
        "Content-Type": "application/json",
        Language: lang
      }
    });

    return next.handle(jsonReq);
  }
}
