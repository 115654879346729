import { Component, OnInit } from "@angular/core";
import { AlertifyService } from "src/app/shared/_services/alertify/alertify.service";
import { Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
declare var $: any;
@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit {
  textDir: string = "rtl";
  static textDir: any;
  constructor(
    private alertify: AlertifyService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
  
      if (event.lang == "ar") {
        this.textDir = "rtl";
        localStorage.setItem("textDir","rtl");
      } else {
        this.textDir = "ltr";
        localStorage.setItem("textDir","ltr");
      }
    });
  }

  ngOnInit() {}
  loggOut() {
    $("#logoutModal").hide();
    localStorage.removeItem("token");
    this.alertify.warning("logged out Successfully");
    this.router.navigate(["/login"]);
  }
}
