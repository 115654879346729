import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { AuthService } from "../common/_services/auth/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { NotificationsService } from "../common/_services/notifications/notifications.service";

declare var $: any;
@Component({
  selector: "app-partial-top-bar",
  templateUrl: "./partial-top-bar.component.html",
  styleUrls: ["./partial-top-bar.component.css"]
})
export class PartialTopBarComponent implements OnInit, AfterViewChecked {
  countOfMessagesToReturn: number = 5;

  unReadedNotificationCount: number;

  topNotification: Notification[] = [];

  decodedToken: any;

  jwtHelper = new JwtHelperService();

  lang: string;

  constructor(
    authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    private notificationsService: NotificationsService
  ) {
    let browserLang = localStorage.getItem("browserLang");
    if (browserLang === null) browserLang = "ar";
    translate.use(browserLang.match(/en|ar/) ? browserLang : "ar");
  }

  topMessages() {

    this.notificationsService.getby(this.countOfMessagesToReturn).subscribe(
      (response: any) => {

        this.topNotification = response.records;
        this.unReadedNotificationCount = response.unreadCount;
      },
      err => {
        console.log(err);
      },
      () => {
        console.log("complete");
      }
    );
  }
  ngOnInit() {
    const token = localStorage.getItem("token");

    this.decodedToken = this.jwtHelper.decodeToken(token);

    this.lang = localStorage.getItem("browserLang");

    //invoke unreaded messages
    this.topMessages();
  }

  ngAfterViewChecked() {
    $(".dropdown-toggle").dropdown();
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("browserLang", lang);
    this.lang = lang;
    this.router.navigate(["/dashboard"]);
  }
}
