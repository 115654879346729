import { Injectable } from "@angular/core";
import { RootService } from "src/app/core/_services/root/root.service";
import { Observable } from "rxjs";
import { Notifications } from "../../_models/notifications/notifications";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class NotificationsService {
  endPoints = {
    notificationTop: "Notification/top",
    notificationList: "Notification/list",
    notificationnotification: "notification​"
  };

  constructor(private rootService: RootService, private http: HttpClient) {}

  getby(take: number): Observable<Notifications[]> {
    return this.rootService.getRoot(
      `${this.endPoints.notificationTop}/${take}`
    );
  }
  getPages(skip: number, take: number) {
      return this.rootService.getRoot(
      `${this.endPoints.notificationList}/${skip}/${take}`);
  }

  edit(notificationId: number): Observable<any> {
    return this.rootService.putRoot(
      `${this.endPoints.notificationnotification}/${notificationId}`,
      notificationId
    );
  }



}
