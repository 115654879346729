import { Injectable } from "@angular/core";
import { SubscriptionGrid } from "../../_models/subscription-grid/subscription-grid";
import { SubscriptionPaymentGrid } from '../../_models/subscription-payment-grid/subscription-payment-grid';

@Injectable()
export class TrackerService {
  subscriptionPlan(index: number, subscriptionPlan: SubscriptionGrid) {
    return subscriptionPlan.id;
  }

  subscriptionPayment(index: number, subscriptionPayment: SubscriptionPaymentGrid) {
    return subscriptionPayment.id;
  }
}
