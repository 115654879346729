import { NgModule } from "@angular/core";
import { SpinnerModule } from "./spinner/spinner.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { ToastrNotificationModule } from "./toastr-notification/toastr-notification.module";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    SpinnerModule,
    ToastrNotificationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [SpinnerModule, TranslateModule]
})
export class ModulesModule {}
