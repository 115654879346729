import { Injectable } from "@angular/core";
import { RootService } from "src/app/core/_services/root/root.service";
import { SubscriptionPlan } from "../../_models/subscription-plan/subscription-plan";
import { Observable } from "rxjs";
import { Search } from '../../_models/search/search';

@Injectable({
  providedIn: "root"
})
export class SubscriptionPlanService {
  endPoints = {
    subscription: "subscription",
    grid:"subscription/grid"
  };

  constructor(private rootService: RootService) {}

  getby(id: number): Observable<SubscriptionPlan> {
  
    return this.rootService.getRoot(`${this.endPoints.subscription}/${id}`);
  }

  getPages(searchModel: Search) {
    
    return this.rootService.getRoot(`${this.endPoints.grid}/${searchModel.companyId}/${searchModel.skip}/${searchModel.take}/${searchModel.key}`);
  }

  add(subscriptionPlanModel: SubscriptionPlan): Observable<SubscriptionPlan> {
    return this.rootService.postRoot(
      this.endPoints.subscription,
      subscriptionPlanModel
    );
  }

  edit(subscriptionPlanModel: SubscriptionPlan): Observable<SubscriptionPlan> {
    return this.rootService.putRoot(
      `${this.endPoints.subscription}/${subscriptionPlanModel.id}`,
      subscriptionPlanModel
    );
  }

  delete(id: number): Observable<void> {
  
    return this.rootService.deleteRoot(this.endPoints.subscription, `${id}`);
  }
}
