import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Country } from "../../_models/country/country";
import { RootService } from 'src/app/core/_services/root/root.service';

@Injectable({
  providedIn: "root"
})
export class CountryService {
  endPoints = {
    countries: "Locale/countries",
    signalR: "Locale"
  };

  constructor(private rootService: RootService) {}
  get(): Observable<Country[]> {
    return this.rootService.getRoot(this.endPoints.countries);
  }
  getSignal(): Observable<any> {

    return this.rootService.getRoot(this.endPoints.signalR);
  }
}
