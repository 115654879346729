import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
 import { AuthService } from "src/app/common/_services/auth/auth.service";
// import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
     private authService: AuthService,
    private router: Router,
    // private toastr: ToastrService
  ) {}

  canActivate(): boolean {
   if (this.authService.loggedIn()) {
     return true;
   }  
 
    // this.toastr.error("You shall not pass!!!.", "", {
    //   positionClass: "toast-bottom-left"
    // });
    this.router.navigate(["/login"]);
    return false;
  }
}
