import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Country } from "../common/_models/country/country";
import { City } from "../common/_models/city/city";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CityService } from "../common/_services/city/city.service";
import { CountryService } from "../common/_services/country/country.service";
import { DcValidation } from "../shared/_validation/dc-validation";
import { AlertifyService } from "../shared/_services/alertify/alertify.service";
import { Govern } from "../common/_models/govern/govern";
import { GovernService } from "../common/_services/govern/govern.service";
import { SubscriptionCompanyInformation } from "../common/_models/subscription-company-information/subscription-company-information";
import { SubscriptionCompanyInformationService } from "../common/_services/subscription-company-information/subscription-company-information.service";
import { JsonErrorMessagesService } from "../shared/_services/json-error-messages/json-error-messages.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: "app-subscription-company-information",
  templateUrl: "./subscription-company-information.component.html",
  styleUrls: ["./subscription-company-information.component.css"]
})
export class SubscriptionCompanyInformationComponent implements OnInit {
  // #region declear

  form: FormGroup;

  jwtHelper = new JwtHelperService();

  // Variables
  subscriptionCompanyInformation = {} as SubscriptionCompanyInformation;

  isUpdate: boolean;

  countries: Country[];

  governs: Govern[];

  cities: City[];

  // Error
  formErrors = {};

  errorMessages = [];

  validationMessages;

  errorMessagesToaster;

  textDir;
  //#endregion

  // #region lookups
  private getCountries() {
    this.spinner.show();
    this.form.patchValue({ city: [] });
    this.countryService.get().subscribe(
      (response: Country[]) => {
        this.countries = response;
      },
      err => {
        this.alertify.error(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.getCountries.en
            : this.errorMessagesToaster.getCountries.ar
        );

        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  private getGovern(id: number) {
    this.form.patchValue({ govern: [] });
    this.spinner.show();
    this.governService.getBy(id).subscribe(
      (response: Govern[]) => {
        this.governs = response;
      },
      err => {
        this.alertify.error(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.getGovern.en
            : this.errorMessagesToaster.getGovern.ar
        );
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  private getCities(id: number) {
    this.spinner.show();
    this.form.patchValue({ city: [] });
    this.cityService.getBy(id).subscribe(
      (response: City[]) => {
        this.cities = response;
      },
      err => {
        this.alertify.error(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.getCities.en
            : this.errorMessagesToaster.getCities.ar
        );
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  //#endregion

  //#region is Update
  isExist() {
    const token = localStorage.getItem("token");
    const userGUI = this.jwtHelper.decodeToken(token).nameid;
    this.subscriptionCompanyInformationService.get(userGUI).subscribe(
      (subscriptionCompanyInformation: SubscriptionCompanyInformation) => {
        this.isUpdate = subscriptionCompanyInformation ? true : false;
        if (subscriptionCompanyInformation) {
          this.subscriptionCompanyInformation = subscriptionCompanyInformation;
          this.getGovern(subscriptionCompanyInformation.countryId);
          this.getCities(subscriptionCompanyInformation.governId);
          this.editSubscriptionCompanyInformation(
            subscriptionCompanyInformation
          );
        }
      },
      (err: any) => console.log(err)
    );
  }

  editSubscriptionCompanyInformation(
    subscriptionCompanyInformation: SubscriptionCompanyInformation
  ) {
    this.form.patchValue({
      companyName: subscriptionCompanyInformation.companyName,
      tenant: subscriptionCompanyInformation.tenant,
      email: subscriptionCompanyInformation.email,
      country: subscriptionCompanyInformation.countryId,
      govern: subscriptionCompanyInformation.governId,
      city: subscriptionCompanyInformation.cityId,
      phone: subscriptionCompanyInformation.phone,
      mobile: subscriptionCompanyInformation.mobile,
      address: subscriptionCompanyInformation.address
    });
  }
  //#endregion

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private countryService: CountryService,
    private governService: GovernService,
    private cityService: CityService,
    private subscriptionCompanyInformationService: SubscriptionCompanyInformationService,
    private alertify: AlertifyService,
    private jsonErrorMessagesService: JsonErrorMessagesService,
    public translate: TranslateService,

  ) {}

  getValidationMessages() {
    this.jsonErrorMessagesService.getJSON().subscribe(messages => {
      this.validationMessages = messages.subscriptionCompanyInformation;
    });
  }

  getErrorMessagesToaster() {
    this.jsonErrorMessagesService.getJSONToaster().subscribe(messages => {
      this.errorMessagesToaster = messages.subscriptionCompanyInformation;
    });
  }

  ngOnInit() {
  this.textDir=localStorage.getItem("textDir");
    this.getValidationMessages();
    this.getErrorMessagesToaster();
    this.form = this.fb.group({
      companyName: ["", [Validators.required]],
      tenant: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required]],
      mobile: ["", [Validators.required]],
      address: ["", [Validators.required]],
      country: ["", [Validators.required]],
      govern: ["", [Validators.required]],
      city: ["", [Validators.required]]
    });

    // When any of the form control value in employee form changes
    // our validation function logValidationErrors() is called
    this.form.valueChanges.subscribe(data => {
      this.errorMessages = DcValidation.logValidationErrors(
        this.form,
        this.formErrors,
        this.validationMessages,
        this.errorMessages
      );
    });

    this.getCountries();

    this.isExist();
  }

  mapFormValues() {
    this.subscriptionCompanyInformation.companyName = this.form.value.companyName;
    this.subscriptionCompanyInformation.tenant = this.form.value.tenant;
    this.subscriptionCompanyInformation.email = this.form.value.email;
    this.subscriptionCompanyInformation.countryId = this.form.value.country;
    this.subscriptionCompanyInformation.governId = this.form.value.govern;
    this.subscriptionCompanyInformation.cityId = this.form.value.city;
    this.subscriptionCompanyInformation.phone = this.form.value.phone;
    this.subscriptionCompanyInformation.mobile = this.form.value.mobile;
    this.subscriptionCompanyInformation.address = this.form.value.address;
  }

  submit(): void {
    this.spinner.show();
    this.mapFormValues();

    if (!this.isUpdate) {
      this.subscriptionCompanyInformationService
        .add(this.subscriptionCompanyInformation)
        .subscribe(
          (response: SubscriptionCompanyInformation) => {
            localStorage.setItem("companyId", response.id.toString());
            this.alertify.success(
              this.translate.currentLang === "en"
                ? this.errorMessagesToaster.submitSuccess.en
                : this.errorMessagesToaster.submitSuccess.ar
            );
          },
          err => {
            this.alertify.error(
              this.translate.currentLang === "en"
                ? this.errorMessagesToaster.submitError.en
                : this.errorMessagesToaster.submitError.ar
            );

            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
            this.router.navigate(["/dashboard"]);
          }
        );
    } else {
      this.subscriptionCompanyInformationService
        .edit(this.subscriptionCompanyInformation)
        .subscribe(
          () =>
            this.alertify.success(
              this.translate.currentLang === "en"
                ? this.errorMessagesToaster.updateSuccess.en
                : this.errorMessagesToaster.updateSuccess.ar
            ),
          err => {
            this.alertify.error(
              this.translate.currentLang === "en"
                ? this.errorMessagesToaster.updateError.en
                : this.errorMessagesToaster.updateError.ar
            );
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
            this.router.navigate(["/dashboard"]);
          }
        );
    }
  }
}
