import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Govern } from "../../_models/govern/govern";
import { RootService } from "src/app/core/_services/root/root.service";

@Injectable({
  providedIn: "root"
})
export class GovernService {
  constructor(private rootService: RootService) {}

  getBy(id: number): Observable<Govern[]> {
    return this.rootService.getRoot(`Locale/${id}/governs`);
  }
}
