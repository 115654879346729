import { NgModule } from "@angular/core";
import { JsonErrorMessagesService } from "./json-error-messages/json-error-messages.service";
import { AlertifyService } from './alertify/alertify.service';

@NgModule({
  imports: [],
  exports: [],
  providers: [JsonErrorMessagesService,
    AlertifyService]
})
export class ServicesModule {}
