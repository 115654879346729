import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl
} from "@angular/forms";

import swal from "sweetalert";
import * as moment from "moment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { SubscriptionPlan } from "src/app/common/_models/subscription-plan/subscription-plan";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertifyService } from "src/app/shared/_services/alertify/alertify.service";
import { JsonErrorMessagesService } from "src/app/shared/_services/json-error-messages/json-error-messages.service";
import { SubscriptionPlanService } from "src/app/common/_services/subscription-plan/subscription-plan.service";
import { DcValidation } from "src/app/shared/_validation/dc-validation";
import { SubscriptionPaymentMethodType } from "src/app/common/_models/subscription-payment-method-type/subscription-payment-method-type";
import { SubscriptionPaymentMethodTypeService } from "src/app/common/_services/subscription-payment-method-type/subscription-payment-method-type.service";
import { FeatureService } from "src/app/common/_services/feature/feature.service";
import { Feature } from "src/app/common/_models/feature/feature";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"]
})
export class AddComponent implements OnInit {
  // #region * declear

  form: FormGroup;

  jwtHelper = new JwtHelperService();

  subscriptionPlan = {} as SubscriptionPlan;

  startDate = null;

  endDate = null;

  subscriptionPaymentMethodType: SubscriptionPaymentMethodType[];

  features: Feature[] = [];

  routeId = 0;

  companyId;

  textDir;
  // #endregion

  // #region * Error
  formErrors = {};

  errorMessages = [];

  validationMessages;

  errorMessagesToaster;
  //#endregion

  // #region * Is Has Company ID ?  and is add or edit?
  isExist() {
    this.companyId = localStorage.getItem("companyId");
    if (this.companyId === null) {
      swal(
        this.translate.currentLang === "en"
          ? this.errorMessagesToaster.isExist.en
          : this.errorMessagesToaster.isExist.ar,
        this.translate.currentLang === "en"
          ? this.errorMessagesToaster.isExistBody.en
          : this.errorMessagesToaster.isExistBody.ar,
        "error"
      ).then(value => {
        if (value === true) this.router.navigate(["/companyinformation"]);
      });
    }

    this.activatedRoute.paramMap.subscribe(parameterMap => {
      this.routeId = +parameterMap.get("id");
    });
    if (this.routeId > 0) {
      this.subscriptionPlanService
        .getby(this.routeId)
        .subscribe((subscriptionPlan: SubscriptionPlan) => {
          this.features = subscriptionPlan.features;

          this.editSubscriptionPlan(subscriptionPlan);
        });
    }
  }
  //#endregion

  // #region * Update

  editSubscriptionPlan(subscriptionPlan: SubscriptionPlan) {
    this.form.patchValue({
      startDate: new Date(subscriptionPlan.startDate),
      endDate: new Date(subscriptionPlan.endDate),
      paymentMethodId: subscriptionPlan.paymentMethodId,
      features: subscriptionPlan.features
    });
    // this.form.value.startDate = moment(subscriptionPlan.startDate).format("DD-MM-YYYY");
    // this.form.value.endDate = moment(subscriptionPlan.endDate).format("DD-MM-YYYY");
  }
  // #endregion

  // #region * LookUp

  private getSubscriptionMethod() {
    this.spinner.show();

    this.subscriptionPaymentMethodTypeService.get().subscribe(
      (response: SubscriptionPaymentMethodType[]) => {
        this.subscriptionPaymentMethodType = response;
      },
      err => {
        this.alertify.error(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.getSubscriptionMethod.en
            : this.errorMessagesToaster.getSubscriptionMethod.ar
        );
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  private getFeatures() {
    this.spinner.show();
    this.featureService.get().subscribe(
      (response: Feature[]) => {
        this.features = response;
        this.onInitFeatures();
      },
      err => {
        this.alertify.error(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.getSubscriptionMethod.en
            : this.errorMessagesToaster.getSubscriptionMethod.ar
        );

        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  onInitFeatures() {
    const control = <FormArray>this.form.controls.features;
    this.features.map(element => {
      control.push(new FormControl(element));
    });
  }

  onChange(feature: Feature, isChecked: boolean) {
    let controlFeatures = this.features;
    for (let control of controlFeatures) {
      if (control.id === feature.id) {
        control.isSelected = isChecked;
      }
    }
  }

  mapFormValues() {
    if (this.routeId > 0) {
      this.subscriptionPlan.id = this.routeId;
      this.onInitFeatures();
    }
    this.subscriptionPlan.paymentMethodId = this.form.value.paymentMethodId;
    this.subscriptionPlan.startDate = this.form.value.startDate;
    this.subscriptionPlan.endDate = this.form.value.endDate;
    this.subscriptionPlan.features = this.form.value.features.filter(function (e) { return e.isSelected; });
    this.subscriptionPlan.companyId = +localStorage.getItem("companyId");
  }
  // #endregion

  // #region * Check Date
  onBlurFrom(event: any): void {
    this.startDate = event;
    if (this.startDate !== null && this.endDate !== null) {
      let isBefore = moment(this.startDate).isBefore(this.endDate);
      if (!isBefore) {
        swal(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.onBlurFromTitle.en
            : this.errorMessagesToaster.onBlurFromTitle.ar,
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.onBlurFromBody.en
            : this.errorMessagesToaster.onBlurFromBody.ar,
          "error"
        );
        this.form.setErrors({ invalid: true });
      }
    }
  }

  onBlurTo(event: any): void {
    this.endDate = event;
    if (this.startDate !== null && this.endDate !== null) {
      let isafter = moment(this.endDate).isAfter(this.startDate);
      if (!isafter) {
        swal(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.onBlurToTitle.en
            : this.errorMessagesToaster.onBlurToTitle.ar,
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.onBlurToBody.en
            : this.errorMessagesToaster.onBlurToBody.ar,
          "error"
        );
        this.form.setErrors({ invalid: true });
      }
    }
  }

  // #endregion  * Check Date
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private subscriptionPlanService: SubscriptionPlanService,
    private subscriptionPaymentMethodTypeService: SubscriptionPaymentMethodTypeService,
    private alertify: AlertifyService,
    private jsonErrorMessagesService: JsonErrorMessagesService,
    private featureService: FeatureService,
    public translate: TranslateService
  ) {}

  getValidationMessages() {
    this.jsonErrorMessagesService.getJSON().subscribe(messages => {
      this.validationMessages = messages.subscriptionPlan;
    });
  }

  getErrorMessagesToaster() {
    this.jsonErrorMessagesService.getJSONToaster().subscribe(messages => {
      this.errorMessagesToaster = messages.payment;
    });
  }

  ngOnInit() {
    this.textDir = localStorage.getItem("textDir");
    this.getErrorMessagesToaster();
    this.getValidationMessages();

    this.isExist();

    this.form = this.fb.group({
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      paymentMethodId: ["", [Validators.required]],
      features: this.fb.array([])
    });

    // When any of the form control value in employee form changes
    // our validation function logValidationErrors() is called
    this.form.valueChanges.subscribe(data => {
      this.errorMessages = DcValidation.logValidationErrors(
        this.form,
        this.formErrors,
        this.validationMessages,
        this.errorMessages
      );
    });
    this.getSubscriptionMethod();

    if (this.routeId > 0) {
      return;
    } else {
      this.getFeatures();
    }
  }

  submit() {
    this.mapFormValues();
    if (this.routeId > 0) {
      //update

      this.subscriptionPlanService.edit(this.subscriptionPlan).subscribe(
        () =>
          this.alertify.success(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.updateSuccess.en
              : this.errorMessagesToaster.updateSuccess.ar
          ),
        err => {
          this.alertify.error(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.updateError.en
              : this.errorMessagesToaster.updateError.ar
          );

          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.router.navigate(["/planlist"]);
        }
      );
    } else {
      //add

      this.subscriptionPlanService.add(this.subscriptionPlan).subscribe(
        () => {
          // this.alertify.success(
          //   this.translate.currentLang === "en"
          //     ? this.errorMessagesToaster.submitSuccess.en
          //     : this.errorMessagesToaster.submitSuccess.ar
          // );
        },
        err => {
          this.alertify.error(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.submitSuccess.en
              : this.errorMessagesToaster.submitSuccess.ar
          );

          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.router.navigate(["/planlist"]);
        }
      );
    }
  }
}
