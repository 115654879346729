import { Injectable } from "@angular/core";
import { RootService } from "src/app/core/_services/root/root.service";
import { SubscriptionCompanyInformation } from "../../_models/subscription-company-information/subscription-company-information";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SubscriptionCompanyInformationService {
  endPoints = {
    company: "company"
  };

  constructor(private rootService: RootService) {}
  add(
    subscriptionCompanyInformationModel: SubscriptionCompanyInformation
  ): Observable<SubscriptionCompanyInformation> {
    return this.rootService.postRoot(
      this.endPoints.company,
      subscriptionCompanyInformationModel
    );
  }

  edit(
    subscriptionCompanyInformationModel: SubscriptionCompanyInformation
  ): Observable<SubscriptionCompanyInformation> {
    return this.rootService.putRoot(
      `${this.endPoints.company}`,
      subscriptionCompanyInformationModel
    );
  }

  get(id: string): Observable<SubscriptionCompanyInformation> {
    return this.rootService.getRoot(`${this.endPoints.company}/${id}`);
  }
}
