import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  imports: [
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }) // ToastrModule added
  ],
  declarations: [],
  exports: [ToastrModule]
})
export class ToastrNotificationModule {}
