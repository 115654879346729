import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxBootstrapValorModule } from "./ngx-bootstrap-valor/ngx-bootstrap-valor.module";
import { PaginationModule } from "./pagination/pagination.module";

@NgModule({
  declarations: [],
  imports: [NgSelectModule, NgxBootstrapValorModule,PaginationModule],
  exports: [NgSelectModule, NgxBootstrapValorModule,PaginationModule ]
})
export class ComponentsModule {}
