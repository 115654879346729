import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl
} from "@angular/forms";

import swal from "sweetalert";
import * as moment from "moment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { SubscriptionPlan } from "src/app/common/_models/subscription-plan/subscription-plan";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertifyService } from "src/app/shared/_services/alertify/alertify.service";
import { JsonErrorMessagesService } from "src/app/shared/_services/json-error-messages/json-error-messages.service";
import { SubscriptionPlanService } from "src/app/common/_services/subscription-plan/subscription-plan.service";
import { DcValidation } from "src/app/shared/_validation/dc-validation";
import { SubscriptionPaymentMethodType } from "src/app/common/_models/subscription-payment-method-type/subscription-payment-method-type";
import { SubscriptionPaymentMethodTypeService } from "src/app/common/_services/subscription-payment-method-type/subscription-payment-method-type.service";
import { FeatureService } from "src/app/common/_services/feature/feature.service";
import { Feature } from "src/app/common/_models/feature/feature";
import { SubscriptionPaymentService } from "src/app/common/_services/subscription-payment/subscription-payment.service";
import { SubscriptionPaymentGrid } from "src/app/common/_models/subscription-payment-grid/subscription-payment-grid";
import { Upload } from "src/app/common/_models/upload/upload";
import { Status } from "src/app/common/_enum/status/status.enum";
import { Activation } from "src/app/common/_models/activation/activation";
import { Payment } from "src/app/common/_models/payment/payment";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-add-payment",
  templateUrl: "./add-payment.component.html",
  styleUrls: ["./add-payment.component.css"]
})
export class AddPaymentComponent implements OnInit {
  // #region * declear
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;

  form: FormGroup;

  features: Feature[] = [];

  payments: Payment[] = [];

  routeId = 0;

  isDisabled = false;

  textStatus = "";

  subscriptionPaymentGridStatus: Status;

  uploads = {} as Upload;

  activation = {} as Activation;

  //#endregion

  //#region * Error
  formErrors = {};

  errorMessages = [];

  validationMessages;

  errorMessagesToaster;

  //#endregion

  editSubscriptionPlan(subscriptionPaymentGrid: SubscriptionPaymentGrid) {
    this.form.patchValue({
      paymentDate: new Date(subscriptionPaymentGrid.paymentDate),
      activationDate: subscriptionPaymentGrid.activationDate?new Date(subscriptionPaymentGrid.activationDate):new Date(),
      startDate: subscriptionPaymentGrid.startDate?new Date(subscriptionPaymentGrid.startDate):new Date(),
      endDate: subscriptionPaymentGrid.endDate?new Date(subscriptionPaymentGrid.endDate):new Date(),
      companyName: subscriptionPaymentGrid.companyName,
      userName: subscriptionPaymentGrid.userName,
      accountEmail: subscriptionPaymentGrid.accountEmail,
      tenant: subscriptionPaymentGrid.tenant,
      subscriptionType: subscriptionPaymentGrid.subscriptionType
    });
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private subscriptionPaymentService: SubscriptionPaymentService,
    private subscriptionPaymentMethodTypeService: SubscriptionPaymentMethodTypeService,
    private alertify: AlertifyService,
    private jsonErrorMessagesService: JsonErrorMessagesService,
    private featureService: FeatureService,
    public translate: TranslateService
  ) {}

  toggleActive(): void {
    this.activation = {
      id: this.routeId
    };

    if (this.subscriptionPaymentGridStatus === Status.Active || this.subscriptionPaymentGridStatus === Status.Running) {
      this.subscriptionPaymentService.deactivate(this.activation).subscribe(
        () =>
          this.alertify.success(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.deactivateSuccess.en
              : this.errorMessagesToaster.deactivateSuccess.ar
          ),
        err => {
          this.alertify.error(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.deactivateError.en
              : this.errorMessagesToaster.deactivateError.ar
          );
          this.spinner.hide();
          this.router.navigate(["/planlist"]);
        }
      );
    } else {
      this.subscriptionPaymentService.activate(this.activation).subscribe(
        () =>
          this.alertify.success(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.activateSuccess.en
              : this.errorMessagesToaster.activateSuccess.ar
          ),
        err => {
          this.alertify.error(
            this.translate.currentLang === "en"
              ? this.errorMessagesToaster.activateError.en
              : this.errorMessagesToaster.activateError.ar
          );

          this.spinner.hide();
          this.router.navigate(["/planlist"]);
        }
      );
    }
  }

  paymentBy(routeId: number) {
    this.subscriptionPaymentService
      .getPaymentBy(routeId)
      .subscribe((subscriptionPaymentGrid: SubscriptionPaymentGrid) => {
        this.features = subscriptionPaymentGrid.features;
        this.subscriptionPaymentGridStatus = subscriptionPaymentGrid.status;
        switch (subscriptionPaymentGrid.status) {
          case Status.Paid: {
            this.textStatus = this.translate.currentLang === "en" ? "Activate" : "تفعيل";
            this.isDisabled = false;
            break;
          }
          case Status.Running:
          case Status.Active: {
            this.textStatus = this.translate.currentLang === "en" ? "Deactivate" : "إلغاء تنشيط";
            this.isDisabled = false;
            break;
          }
          case Status.InActive: {
            this.textStatus = this.translate.currentLang === "en" ? "Activate" : "تفعيل";
            this.isDisabled = false;
            break;
          }
          case Status.Waiting: {
            this.textStatus = this.translate.currentLang === "en" ? "Activate" : "تفعيل";
            this.isDisabled = true;
            break;
          }
          case Status.Deleted: {
            // Delete
            this.textStatus = this.translate.currentLang === "en" ? "Activate" : "تفعيل";
            this.isDisabled = false;
            break;
          }
        }

        this.editSubscriptionPlan(subscriptionPaymentGrid);
      });
  }

  archive(routeId: number) {
    this.subscriptionPaymentService
      .getArchive(routeId)
      .subscribe((payments: Payment[]) => {

        this.payments = payments;
      });
  }

  getImage(id: number) {
    const receipt = environment.serverUrl + `${"payment/receipt"}/${id}`;
    window.open(receipt, "_blank");
  }

  getValidationMessages() {
    this.jsonErrorMessagesService.getJSON().subscribe(messages => {
      this.validationMessages = messages.payment;
    });
  }

  getErrorMessagesToaster() {
    this.jsonErrorMessagesService.getJSONToaster().subscribe(messages => {
      this.errorMessagesToaster = messages.payment;
    });
  }

  submit(): void {
    this.spinner.show();
    this.activatedRoute.paramMap.subscribe(parameterMap => {
      this.routeId = +parameterMap.get("id");
    });

    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      this.uploads = {
        amount: this.form.value.payment.toString(),
        receipt: fileToUpload,
        subscriptionId: this.routeId.toString()
      };

      this.subscriptionPaymentService.upload(this.uploads).subscribe(
        () => this.alertify.success("Successfully Uploaded"),
        err => {
          this.alertify.error(
            "Check your end point 'API' to 'Payment/upload'."
          );
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.router.navigate(["/planlist"]);
        }
      );
    }
  }

  ngOnInit() {
    this.getValidationMessages();

    this.getErrorMessagesToaster();

    this.activatedRoute.paramMap.subscribe(parameterMap => {
      this.routeId = +parameterMap.get("id");
    });

    this.form = this.fb.group({
      companyName: ["", [Validators.required]],
      tenant: ["", [Validators.required]],
      userName: ["", [Validators.required]],
      accountEmail: ["", [Validators.required]],
      activationDate: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      paymentDate: ["", [Validators.required]],
      subscriptionType: ["", [Validators.required]],
      payment: ["", [Validators.required]],
      file: ["", [Validators.required]],
      features: this.fb.array([])
    });

    // When any of the form control value in employee form changes
    // our validation function logValidationErrors() is called
    this.form.valueChanges.subscribe(data => {
      this.errorMessages = DcValidation.logValidationErrors(
        this.form,
        this.formErrors,
        this.validationMessages,
        this.errorMessages
      );
    });

    this.paymentBy(this.routeId);
    this.archive(this.routeId);
  }
}
