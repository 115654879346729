import { Component, OnInit } from "@angular/core";
import { NotificationsService } from "../common/_services/notifications/notifications.service";
import { JsonErrorMessagesService } from "../shared/_services/json-error-messages/json-error-messages.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"]
})
export class NotificationsComponent implements OnInit {
  topNotification: Notification[] = [];

  validationMessages;

  errorMessagesToaster;

  message;

  totalNotificationCount: number;

  // paging

  currentPage = 1;
  numberPerPage = 10;
  numberOfPages = 1; // calculates the total number of pages
  end;
  begin;

  constructor(
    private notificationsService: NotificationsService,
    private jsonErrorMessagesService: JsonErrorMessagesService,
    private spinner: NgxSpinnerService
  ) {}

  // load th next 6 posts
  loadNextPost(skip: number, take: number) {
    this.loadInitPost(skip, take);
    const lastPost = this.topNotification.length;
  }

  onScroll() {
    this.currentPage += 1;
    this.begin = (this.currentPage - 1) * this.numberPerPage;
    this.end = this.begin + this.numberPerPage;
    if (this.totalNotificationCount >= this.end) {
      this.loadNextPost(0, this.end);
    }
  }

  getValidationMessages() {
    this.jsonErrorMessagesService.getJSON().subscribe(messages => {
      this.validationMessages = messages.subscriptionCompanyInformation;
    });
  }
  getErrorMessagesToaster() {
    this.jsonErrorMessagesService.getJSONToaster().subscribe(messages => {
      this.errorMessagesToaster = messages.subscriptionCompanyInformation;
    });
  }

  showNotification(message: string) {
    this.message = message;
  }
  ngOnInit() {
    this.begin = (this.currentPage - 1) * this.numberPerPage;
    this.end = this.begin + this.numberPerPage;
    this.loadInitPost(this.begin, this.end);
  }
  //load the Initial 6 posts
  loadInitPost(skip: number, take: number) {
    this.notificationsService.getPages(skip, take).subscribe(response => {
      debugger
      this.topNotification = response.records;
      this.totalNotificationCount =
        Math.ceil(response.totalRecords / 100) * 100;
    });
  }
}
