import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
declare var $: any;
@Component({
  selector: 'app-partial-side-bar',
  templateUrl: './partial-side-bar.component.html',
  styleUrls: ['./partial-side-bar.component.css']
})
export class PartialSideBarComponent implements OnInit {

  decodedRole: any;
  jwtHelper = new JwtHelperService();
  constructor() { }

  ngOnInit() {
    const token = localStorage.getItem("token");
    this.decodedRole = this.jwtHelper.decodeToken(token).role;
  }
  toggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar .collapse").collapse("hide");
    }
  }
}
