import { Routes } from '@angular/router';
import { AuthLoginComponent } from 'src/app/auth-login/auth-login.component';
import { AuthRegisterComponent } from 'src/app/auth-register/auth-register.component';
import { AuthForgetPasswordComponent } from 'src/app/auth-forget-password/auth-forget-password.component';




export const PUBLIC_ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: AuthLoginComponent },
  { path: "register", component: AuthRegisterComponent },
  { path: "forgotPassword", component: AuthForgetPasswordComponent }
];