import { Routes } from "@angular/router";
import { SubscriptionDashboardComponent } from "src/app/subscription-dashboard/subscription-dashboard.component";
import { SubscriptionCompanyInformationComponent } from "src/app/subscription-company-information/subscription-company-information.component";
import { ListComponent } from "src/app/subscription-plan/list/list.component";
import { AddComponent } from "src/app/subscription-plan/add/add.component";
import { AddPaymentComponent } from "src/app/subscription-payment/add-payment/add-payment.component";
import { ListPaymentComponent } from "src/app/subscription-payment/list-payment/list-payment.component";
import { NotificationsComponent } from 'src/app/notifications/notifications.component';

export const SECURE_ROUTES: Routes = [
  { path: "dashboard", component: SubscriptionDashboardComponent },

  { path: "companyinformation", component: SubscriptionCompanyInformationComponent },

  { path: "plan", component: AddComponent },
  { path: "plan/edit/:id", component: AddComponent },
  { path: "planlist", component: ListComponent },

  { path: "payment", component: AddPaymentComponent },
  { path: "payment/edit/:id", component: AddPaymentComponent },
  { path: "paymentlist", component: ListPaymentComponent },

  { path: "notifications", component: NotificationsComponent }
];
