import { Injectable } from "@angular/core";
import { RootService } from "src/app/core/_services/root/root.service";
import { Observable } from "rxjs";
import { Search } from "../../_models/search/search";
import { Status } from "../../_models/status/status";
import { SubscriptionPaymentGrid } from "../../_models/subscription-payment-grid/subscription-payment-grid";

import { Upload } from "../../_models/upload/upload";
import { Activation } from "../../_models/activation/activation";
import { Payment } from "../../_models/payment/payment";

@Injectable({
  providedIn: "root"
})
export class SubscriptionPaymentService {
  endPoints = {
    status: "payment/status",
    paymentList: "payment/List",
    payment: "payment",
    upload: "Payment/upload",
    activate: "payment/activate",
    deactivate: "Payment/deactivate",
    archiveList: 'payment/archive',
    receiptImage: "payment/receipt"
  };

  constructor(private rootService: RootService) {}

  getStatus(): Observable<Status[]> {
    return this.rootService.getRoot(this.endPoints.status);
  }

  getby(StatusId: number): Observable<SubscriptionPaymentGrid[]> {
    return this.rootService.getRoot(
      `${this.endPoints.paymentList}/${StatusId}`
    );
  }

  getArchive(SubscriptionId: number): Observable<Payment[]> {

    return this.rootService.getRoot(
      `${this.endPoints.archiveList}/${SubscriptionId}`
    );
  }

  getReceipt(PaymentId: number): Observable<any> {

    return this.rootService.getRoot(
      `${this.endPoints.receiptImage}/${PaymentId}`
    );
  }

  getPaymentBy(id: number): Observable<SubscriptionPaymentGrid> {
    return this.rootService.getRoot(`${this.endPoints.payment}/${id}`);
  }
  upload(fileToUpload: Upload) {
    let input = new FormData();
    input.append("receipt", fileToUpload.receipt);
    input.append("amount", fileToUpload.amount);
    input.append("subscriptionId", fileToUpload.subscriptionId);

    return this.rootService.postRoot(this.endPoints.upload, input);
  }

  deactivate(activation: Activation): Observable<SubscriptionPaymentGrid> {
    //debugger;
    return this.rootService.postRoot(this.endPoints.deactivate, activation);
  }

  activate(activation: Activation): Observable<SubscriptionPaymentGrid> {
    return this.rootService.postRoot(this.endPoints.activate, activation);
  }
}
