import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Login } from "../../_models/login/login";
import { RootService } from "src/app/core/_services/root/root.service";
import { map } from "rxjs/operators";
import { Register } from "../../_models/register/register";
import { Observable } from "rxjs";
import { ForgetPassword } from "../../_models/forget-password/forget-password";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  endPoints = {
    login: "Users/authenticate",
    register: "Users/register",
    forgetPassword: "Users/passwordforget"
  };

  jwtHelper = new JwtHelperService();

  constructor(private rootService: RootService) {}

  login(loginModel: Login) {
    return this.rootService.postRoot(this.endPoints.login, loginModel).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem("token", user.token);
          localStorage.setItem("companyId", user.companyId);
        }
      })
    );
  }

  register(registerModel: Register): Observable<Register> {
    return this.rootService.postRoot(this.endPoints.register, registerModel);
  }

  forgetPassword(
    forgetPasswordModel: ForgetPassword
  ): Observable<ForgetPassword> {
    return this.rootService.postRoot(
      this.endPoints.forgetPassword,
      forgetPasswordModel
    );
  }

  loggedIn() {
    const token = localStorage.getItem("token");
    return !this.jwtHelper.isTokenExpired(token);
  }
}
