import { Injectable } from '@angular/core';
import { RootService } from 'src/app/core/_services/root/root.service';
import { Observable } from 'rxjs';
import { SubscriptionPaymentMethodType } from '../../_models/subscription-payment-method-type/subscription-payment-method-type';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPaymentMethodTypeService {

  endPoints = {
    paymentMethod: "Subscription/Methods"
  };

  constructor(private rootService: RootService) {}
  get(): Observable<SubscriptionPaymentMethodType[]> {
    return this.rootService.getRoot(this.endPoints.paymentMethod);
  }

}
