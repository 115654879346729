import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../common/_services/auth/auth.service";
import { AlertifyService } from "../shared/_services/alertify/alertify.service";
import { JsonErrorMessagesService } from "../shared/_services/json-error-messages/json-error-messages.service";
import { ForgetPassword } from '../common/_models/forget-password/forget-password';
import { DcValidation } from '../shared/_validation/dc-validation';

@Component({
  selector: "app-auth-forget-password",
  templateUrl: "./auth-forget-password.component.html",
  styleUrls: ["./auth-forget-password.component.css"]
})
export class AuthForgetPasswordComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private jsonErrorMessagesService: JsonErrorMessagesService
  ) {}

  // #region
  form: FormGroup;

  forgetPasswordObject = {} as ForgetPassword;

  formErrors = {};

  validationMessages;

  mapFormValues() {
    this.forgetPasswordObject.email = this.form.value.email;
  }
  // #endregion

  ngOnInit() {
    this.jsonErrorMessagesService.getJSON().subscribe(messages => {
      this.validationMessages = messages.login;
    });
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    });

    this.form.valueChanges.subscribe(data => {
      DcValidation.logValidationErrors(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
  }

  forget() {
    this.spinner.show();
    this.mapFormValues();
    this.authService.forgetPassword(this.forgetPasswordObject).subscribe(
      () =>
          this.alertify.success("Please check your email")
        ,
      err => {
        this.alertify.error("Your email is not exist");
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }
}
