import { Component, OnInit } from "@angular/core";
import { SubscriptionGrid } from "src/app/common/_models/subscription-grid/subscription-grid";
import { Router } from "@angular/router";
import { AlertifyService } from "src/app/shared/_services/alertify/alertify.service";
import { SubscriptionPlanService } from "src/app/common/_services/subscription-plan/subscription-plan.service";
import { Search } from "src/app/common/_models/search/search";
import { TrackerService } from "src/app/common/_services/tracker/trackby.service";
import swal from "sweetalert";
@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"]
})
export class ListComponent implements OnInit {
  subscriptionGrid: SubscriptionGrid[] = [];

  totalRecords = 0;

  pageSize = 10;

  pageInitial = 1;

  searchValue = "null";

  currentPage = 1;

  searchObject = {} as Search;
  textDir;
  // sort(prop: string) {
  //   this.sorter.sort(this.filteredgenders, prop);
  // }
  pageChanged(page: number) {
    this.currentPage = page;
    this.getPage(page);
  }

  //#region clear search
  clearSearch() {
    this.searchValue = "null";
    // this.pageChanged(1);
  }
  //#endregion

  //#region search
  search(value: string) {
    this.searchObject = {
      key: value,
      companyId: Number.parseInt(localStorage.getItem("companyId")),
      skip: (this.currentPage - 1) * this.pageSize,
      take: this.pageSize
    };

    this.subscriptionPlanService.getPages(this.searchObject).subscribe(
      (response: any) => {
        this.totalRecords = response.totalRecords;
      },
      err =>
        this.alertify.error(
          "Check your end point 'API' to post Company Information."
        )
    );
  }
  //#endregion

  //#region get
  getPage(page: number) {
    this.searchObject = {
      key: this.searchValue,
      companyId: Number.parseInt(localStorage.getItem("companyId")),
      skip: (page - 1) * this.pageSize,
      take: this.pageSize
    };
    this.subscriptionPlanService.getPages(this.searchObject).subscribe(
      response => {
        this.subscriptionGrid = response.records;
        this.totalRecords = response.totalRecords;
      },
      err =>
        this.alertify.error(
          "Check your end point 'API' to post Company Information."
        ),
      () => console.log("this.spinner.hide")
    );
  }
  // #endregion

  constructor(
    private router: Router,
    private alertify: AlertifyService,
    private subscriptionPlanService: SubscriptionPlanService,
    public tracker: TrackerService
  ) {}

  ngOnInit() {
    this.textDir=localStorage.getItem("textDir");
    if (localStorage.getItem("companyId") == "null") {
      swal("Oops!", "Please fulfill company information!", "error").then(
        value => {
          if (value === true) this.router.navigate(["/companyinformation"]);
        }
      );
    }
    else{
      this.getPage(this.currentPage);
    }
    
  }

  add(): void {
    this.router.navigate(["/plan"]);
  }

  edit(id: number): void {
    this.router.navigate(["/plan/edit", id]);
  }

  delete(id: number): void {
    
    this.subscriptionPlanService.delete(id).subscribe(
      () => {
        this.pageChanged(this.currentPage);
        this.alertify.success("Dleteed success");
      },

      err => {
        this.alertify.error(
          "Check your end point 'API' to Delete Subscription Plan."
        );
      },
      () => {
        // tslint:disable-next-line:max-line-length
      }
    );
  }
}
