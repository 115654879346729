import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { AdminComponent } from './layouts/admin/admin.component';
import { PUBLIC_ROUTES } from './layouts/auth/auth.routing';
import { SECURE_ROUTES } from './layouts/admin/admin.routing';
import { AuthGuard } from './core/_guard/auth-guard/auth.guard';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', component: AuthComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
  { path: '', component: AdminComponent,canActivate: [AuthGuard], data: { title: 'Secure Views' }, children: SECURE_ROUTES },
  { path: '**', redirectTo: 'login' }
];

/**
* App routing module
*/
@NgModule({
  imports: [
      RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
