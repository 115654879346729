import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { City } from "../../_models/city/city";
import { RootService } from 'src/app/core/_services/root/root.service';

@Injectable({
  providedIn: "root"
})
export class CityService {
  constructor(private rootService: RootService) {}

  getBy(id: number): Observable<City[]> {
    return this.rootService.getRoot(`Locale/${id}/cities`);
  }
}
