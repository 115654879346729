import { Component, OnInit } from "@angular/core";
import { TabDirective } from "ngx-bootstrap/tabs";
import { NgxSpinnerService } from "ngx-spinner";
import { SubscriptionPaymentService } from "src/app/common/_services/subscription-payment/subscription-payment.service";
import { Status } from "src/app/common/_models/status/status";
import { AlertifyService } from "src/app/shared/_services/alertify/alertify.service";
import { SubscriptionPaymentGrid } from "src/app/common/_models/subscription-payment-grid/subscription-payment-grid";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { JsonErrorMessagesService } from 'src/app/shared/_services/json-error-messages/json-error-messages.service';

@Component({
  selector: "app-list-payment",
  templateUrl: "./list-payment.component.html",
  styleUrls: ["./list-payment.component.css"]
})
export class ListPaymentComponent implements OnInit {
  tabsList = [];
  subscriptionPaymentGrid: SubscriptionPaymentGrid[] = [];
  errorMessagesToaster;

  constructor(
    private spinner: NgxSpinnerService,
    private subscriptionPaymentService: SubscriptionPaymentService,
    private alertify: AlertifyService,
    private router: Router,
    public translate: TranslateService,
    private jsonErrorMessagesService: JsonErrorMessagesService
  ) {}

  getStuts() {
    this.subscriptionPaymentService.getStatus().subscribe(
      (res: Status[]) => {
        this.tabsList = res;
        this.paymentBasedOnstatus(res[0].id);
      },
      err => {
        this.alertify.error(
          this.translate.currentLang === "en"
            ? this.errorMessagesToaster.getStuts.en
            : this.errorMessagesToaster.getStuts.ar
        );
      }
    );
  }
  onSelect(data: TabDirective): void {
    this.paymentBasedOnstatus(+data.id);
  }

  paymentBasedOnstatus(statusId: number) {
    this.subscriptionPaymentService
      .getby(statusId)
      .subscribe((response: SubscriptionPaymentGrid[]) => {
        this.subscriptionPaymentGrid = response;
      });
  }

  payment(subscriptionId: number) {
    this.router.navigate(["/payment/edit", subscriptionId]);
  }

  getErrorMessagesToaster() {
    this.jsonErrorMessagesService.getJSONToaster().subscribe(messages => {
      this.errorMessagesToaster = messages.payment;
    });
  }
  ngOnInit() {
    this.getErrorMessagesToaster();
    this.getStuts();
  }
}
